<template>
  <div id="app">
    <section class="section position-relative popUpFather">
      <div class="mt-4 flex-wrap justify-content-between">
        <div class="p-0 col-sm-auto mb-2">
          <div class="p-0 col-sm-auto row m-0">
            <div class="d-flex my-auto">
              <div class="my-auto mr-2">
                <h4 class="card-title my-auto">Liste des initiatives</h4>
              </div>
              <DropDownCustom
                :items="['Tableau', 'Kanban', 'Gantt']"
                @changeValue="onValueChange"
              />
            </div>
            <div class="my-auto pl-2 mx-0" v-if="selected == 2">
              <b-dropdown
                id="dropdown-1"
                :text="selectedView.text"
                :class="`custom-drop m-md-2 btn-outline-info ${selectedView.color} `"
              >
                <b-dropdown-item
                  class="col-sm-6 col-md-auto m-0 p-0"
                  v-for="(view, index) in ganttView"
                  :key="index"
                >
                  <button
                    @click="changeView(view, index)"
                    type="button"
                    :class="`btn ${view.color} btn-fw col-sm-12`"
                  >
                    {{ view.text }}
                  </button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="p-0 col-md-12 d-flex ml-auto mr-0 my-auto py-1">
              <div
                class="p-0 my-auto ml-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <b-form-input
                  v-model="searchQuery.libelle"
                  type="text"
                  placeholder="Libellé"
                ></b-form-input>
              </div>
              <div
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  label="value"
                  v-model="searchQuery.responsable"
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  class="bg-white h-100"
                  placeholder="Responsable"
                >
                  <template #selected-option="{ value }">
                    <span class="text-truncate col-12 p-0">{{
                      value
                    }}</span></template
                  ></v-select
                >
              </div>
              <div
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <date-picker
                  :value="new Date()"
                  v-model="searchQuery.startedAt"
                  placeholder="Date début"
                  format="DD/MM/YYYY"
                />
              </div>
              <div
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <date-picker
                  placeholder="Date fin"
                  :value="new Date()"
                  v-model="searchQuery.endingAt"
                  class="bg-white h-100"
                  format="DD/MM/YYYY"
                />
              </div>

              <div
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  v-model="searchQuery.status"
                  :options="statusInitiative"
                  placeholder="Statut"
                  class="bg-white h-100"
                />
              </div>
              <div
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  :options="fdrList"
                  :reduce="(fdr) => fdr.id"
                  label="name"
                  v-model="searchQuery.fdr"
                  placeholder="FdR"
                  class="bg-white h-100"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div
                v-if="isPo || isAdmin || isSuper"
                class="p-0 pl-2 my-auto"
                style="height: 44.22px; width: 20%"
              >
                <CustomAddButton
                  text="Nouvelle initiative"
                  class="px-3 w-100"
                  @click="$router.push({ name: 'add-initiative' })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 bg-secondary" />

      <div v-if="selected == 1" class="board-wrapper pt-5">
        <initiativeKabnan
          :items="filteredInitiatives"
          @transferItem="transform"
        />
      </div>

      <div style="position: relative" v-if="selected == 0">
        <initiative-table
          @deleteItem="deleteItem"
          :items="filteredInitiatives"
          :loading="loadingData"
          @changePage="changePage"
          @transferItem="transform"
          @showItem="$router.push({ name: 'show-initiative' })"
          @editItem="$router.push({ name: 'edit-initiative' })"
        />
      </div>
      <div v-if="selected == 2" class="card p-3 mt-4">
        <Gantt
          :items="filteredInitiatives"
          :loadingData="loadingData"
          :mode="mode"
        />
      </div>
    </section>

    <div v-show="showTransferModal" to="modals-xyz-548">
      <Modal @close="showTransferModal = false">
        <TransferInitiativeModal
          @transferInitiative="transferInitiative"
          :initiatives="INITIATIVES"
          :fdrs="FDRS"
          :initiative="selectedInitiative"
          @close="showTransferModal = false"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import Modal from "@/components/custom/Modal.vue";
import InitiativeTable from "./components/InitiativeTable.vue";
import TransferInitiativeModal from "./components/TransferInitiative.vue";
import initiativeKabnan from "./components/initiativeKabnan.vue";
import { mapGetters } from "vuex";
import Gantt from "./components/Gantt.vue";
import Swal from "sweetalert2";
import moment from "moment";
import CustomAddButton from "@/components/custom/CustomAddButton";
export default {
  name: "Initiative",
  components: {
    DropDownCustom,
    Modal,
    InitiativeTable,
    TransferInitiativeModal,
    initiativeKabnan,
    Gantt,
    CustomAddButton,
  },
  data() {
    return {
      transformedInitiativeId: null,
      ganttView: [
        {
          text: "Jour",
          id: 1,
          color: " btn-inverse-primary",
        },
        {
          text: "Semaine",
          id: 2,
          color: "btn-inverse-danger ",
        },
        {
          text: "Mois",
          id: 3,
          color: "btn-inverse-info ",
        },
      ],
      selectedView: {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      currentPage: 1,
      showTransferModal: false,

      selectedImpact: 0,

      showModal: false,

      mode: "day",
      debugEventLog: [],

      selected: this.$store.state.views.activeView,

      selectedPop: 0,

      tableItems: [],

      selectedRecords: [],
      filtered: false,
      showFilter: false,

      searchInvitedModal: false,
      showInitiative: false,
      addInitiative: false,
      editInitiative: false,
      searchQuery: {
        libelle: "",
        responsable: "",
        status: "",
        fdr: "",
        startedAt: "",
        endingAt: "",
      },
      loadingData: true,
      selectedInitiative: null,
    };
  },
  methods: {
    transform(initiative, initiativeId) {
      this.showTransferModal = true;
      this.transformedInitiativeId = initiativeId;
      this.selectedInitiative = initiative;
    },
    changeView: function (view, val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView.text = view.text;
    },

    onValueChange: function (e) {
      this.selected = e;
      this.$store.dispatch("views/changeView", e);
    },

    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItems = this.tableItems.filter((item) => item.id !== id);
          this.$store.dispatch("initiative/deleteInitiative", id).then(() => {
            this.$store.dispatch("initiative/fetchAllInitiatives").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
    changePage: function () {
      this.loadingData = true;
      this.$store
        .dispatch(
          "initiative/fetchAllInitiatives",
          `transformation/initiatives?page=${this.currentPage}`
        )
        .then(() => {
          this.loadingData = false;
          this.tableItems = this.INITIATIVES;
        });
    },
    transferInitiative(fdrId) {
      this.$store
        .dispatch("initiative/updateInitiative", {
          id: this.transformedInitiativeId,
          feuilleRoute: `/api/transformation/feuille_routes/${fdrId}`,
        })
        .then(() => {
          this.$store.dispatch("initiative/fetchAllInitiatives");
          this.showTransferModal = !this.showTransferModal;
        });
    },
  },
  computed: {
    ...mapGetters("initiative", ["INITIATIVES", "VIEW", "TOTAL_ITEMS"]),
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters(["isPo", "isAdmin", "isSuper"]),
    ...mapGetters("cStatus", ["STATUS_INITIATIVES"]), //get all status

    responsableTitle: function ({ index, value }) {
      if (this.isExpanded[index]) {
        return (
          value.split(" ")[0].substring(0, 1) +
          ". " +
          value.split(" ")[1].substring(0, 1)
        );
      } else {
        return value;
      }
    },
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));

      return data;
    },
    statusInitiative() {
      return this.STATUS_INITIATIVES;
    },
    fdrList() {
      return this.FDRS;
    },

    filteredInitiatives: function () {
      // window.console.table(this.INITIATIVES);
      return this.tableItems
        .filter(
          (initiative) =>
            initiative.libelle
              .toLowerCase()
              .indexOf(this.searchQuery.libelle.toLowerCase()) > -1 &&
            initiative.status
              .toLowerCase()
              .indexOf(
                this.searchQuery.status
                  ? this.searchQuery.status.toLowerCase()
                  : ""
              ) > -1
        )
        .filter((initiative) =>
          this.searchQuery.responsable
            ? initiative.responsable.id ===
              parseInt(this.searchQuery.responsable)
            : true
        )
        .filter((initiative) =>
          this.searchQuery.fdr
            ? initiative.feuilleRoute.id === parseInt(this.searchQuery.fdr)
            : true
        )
        .filter(
          (initiative) =>
            (this.searchQuery.endingAt
              ? moment(initiative.endingAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                ) <=
                moment(this.searchQuery.endingAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                )
              : true) &&
            (this.searchQuery.startedAt
              ? moment(initiative.startedAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                ) >=
                moment(this.searchQuery.startedAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                )
              : true)
        )
        .map((initiative) => ({
          ...initiative,
          // avancement: parseInt(Math.random() * 100),
          actions: ["delete", "show", "transfer"],
        }));
    },
  },
  mounted: function () {},

  created() {
    this.$store.dispatch("initiative/fetchAllInitiatives").then(() => {
      this.loadingData = false;
      this.$store.dispatch("loader/toggleLoading", false);
    });
    this.tableItems = this.INITIATIVES;
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Initiative");
  },
  watch: {
    INITIATIVES: function () {
      this.tableItems = this.INITIATIVES.map((initiative) => initiative);
      // window.console.table(this.INITIATIVES);
    },
  },
};
</script>

<style scoped>
.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background-color: white;
  width: 100%;
  border: 2px #f2edf3 solid;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    /* transform: translateY(-50%) !important; */
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: center; */
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}
</style>

<style>
.v-select div {
  height: 100% !important;
}
.vs__search {
  color: #cfcfcf;
}
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
</style>
