<template>
  <div>
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Initiative :
          </span>
          <span v-if="initiative" style="font-size: 22px">
            {{ initiative.libelle }}
          </span>
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center py-4">
      <p class="mb-0 h4 col-4 p-0">Tranférer les initiatives:</p>
      <b-form-input
        type="email"
        v-model="searchQuery"
        id="input1"
        placeholder="libelle de Fdr"
      ></b-form-input>
      <b-button
        @click="transferInitiative"
        variant="secondary"
        class="ml-2"
        :disabled="selectedFdr === null"
        >Valider</b-button
      >
    </div>
    <b-table
      :fields="fields"
      :items="filtredFdrs"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(id)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            :value="data.value"
            v-model="selectedFdr"
            name="some-radios"
          ></b-form-radio>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    initiatives: Array,
    fdrs: Array,
    initiative: Object,
  },
  data() {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedFdr: null,
      fields: [
        { key: "name", label: "Libellé FdR" },
        { key: "id", label: "Sélection", thStyle: { textAlign: "center" } },
      ],
      searchQuery: "",
    };
  },
  computed: {
    rows: function () {
      return this.filtredFdrs.length;
    },
    filtredFdrs: function () {
      return this.fdrs.filter(
        (fdr) =>
          fdr.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
      );
    },
  },
  methods: {
    transferInitiative() {
      this.$emit("transferInitiative", this.selectedFdr);
    },
  },
};
</script>
