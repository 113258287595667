<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(avancement)="data">
                <b-progress
                  max="100"
                  show-progress
                  height="15px"
                  style="background-color: #cacccd"
                >
                  <b-progress-bar
                    :value="data.value || 0"
                    :label="`${data.value || 0}%`"
                    :style="`background-color: ${
                      data.value > 20 && data.value <= 70
                        ? '#f5f245'
                        : data.value > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </template>

              <template v-slot:cell(deadline)="data">
                <span
                  v-b-tooltip.hover="
                    echeanceStatus(
                      data.item.createdAt,
                      data.item.endingAt,
                      data.item.status
                    ).toUpperCase()
                  "
                  class="text-white text-center px-2 py-1 rounded"
                  :style="{
                    backgroundColor: dateBackgroundColor(
                      data.item.createdAt,
                      data.item.endingAt,
                      data.item.status
                    ),
                  }"
                  >{{ data.item.endingAt }}</span
                >
              </template>
              <template v-slot:cell(phases)="data">
                <span v-if="data.value.find((phase) => phase.isCurrent)"
                  >{{ data.value.find((phase) => phase.isCurrent).libelle }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(feuilleRoute)="data">
                <div>
                  <router-link
                    :to="{
                      name: 'show-fdr',
                      params: {
                        id: data.value.id,
                        slug: data.value.slug || 'abcde',
                      },
                    }"
                    >{{ data.value.name }}</router-link
                  >
                </div>
              </template>

              <template v-slot:cell(libelle)="data">
                <b-link
                  :to="{
                    name: 'show-initiative',
                    params: {
                      slug: data.item.slug || 'abcde',
                      id: data.item.id,
                    },
                  }"
                >
                  {{ data.value }}
                </b-link>
              </template>
              <template v-slot:cell(responsable)="data">
                <TableResponsable :responsable="data.value" />
              </template>

              <template v-slot:cell(nb_initiatives)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    @click="$emit('showInitiatives', data.value)"
                    style="cursor: pointer"
                    ><span></span>{{ data.value }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(status)="data">
                <label>{{ data.value }}</label>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  @transferItem="$emit('transferItem', data.item, data.item.id)"
                  @showItem="
                    $router.push({
                      name: 'show-initiative',
                      params: {
                        slug: data.item.slug || 'abcde',
                        id: data.item.id,
                      },
                    })
                  "
                  @deleteItem="$emit('deleteItem', data.item.id)"
                  @editItem="$emit('editItem')"
                  :actions="['delete', 'transfer']"
                />
              </template>
            </b-table>
          </div>
          <div class="bg-white rounded p-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="10"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import moment from "moment";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, TableResponsable },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: "libelle",
          label: "Initiative",
          sortable: true,
        },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "deadline", label: "Échéances", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "phases", label: "Phase projet", sortable: true },
        {
          key: "feuilleRoute",
          label: "Feuille de route",
          sortable: true,
        },
        { key: "avancement", label: "Avancement", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
  },
  props: {
    items: Array,
    loading: Boolean,
  },
};
</script>
